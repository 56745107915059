/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.row.column-splitter {
  max-width: none;
}

.row > .row.column-splitter {
  flex: 100%;
  margin-right: 0;
  margin-left: 0;
  align-items: center;
}

.spa-container > .row-splitter {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.row > .row-splitter {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 1200px) {
  .no-margin-splitter .col-xl-6:first-child {
    padding-right: 0;
  }
  .no-margin-splitter .col-xl-6:last-child {
    padding-left: 0;
    border-left: 1px solid #fff;
  }
}

.container.margin-top {
  margin-top: 2.4em;
}

.container.margin-bottom {
  margin-bottom: 2.4em;
}
